$(document).ready(() => {
  function fetchReviews (sortOption) {
    const currentEvent = $('.cart-title').data('event-pretty-slug')

    if (sortOption != 'preview') {
      $('#view_more_reviews').text('Please wait...')
    }

    if (sortOption == 'low_to_high') {
      $('#low_alert').show()
    } else {
      $('#low_alert').hide()
    }

    $.ajax({
      url: '/filter_reviews',
      type: 'GET',
      data: { event: currentEvent, sort: sortOption },
      dataType: 'json',
      success: function (reviews) {
        $('#preview_reviews').empty()

        reviews.forEach(function (review) {
          let reviewHTML = `
            <div class="row mb-1">
              <div style="width: 150px !important">
                <div class="cmt-info">
                  <p class="mb-2">
                    <img loading="lazy" class="user-avatar object-fit-cover" src="${review.img}" alt="Reviewer avatar" style="width:70px;height:70px">
                  </p>

                  <div class="name mb-2">
                    <h5 class="font-500">${review.name}</h5>
                  </div>`

          if (review.logo_url != null && review.logo_url != '') {
            reviewHTML += `
                  <p class="mb-2">
                    <img loading="lazy" src="${review.logo_url}" style="width: 50px;" alt="Reviewer company logo">
                  </p>`
          }

          if (review.participant_role == 'Organizer') {
            reviewHTML += `
                  <p class="d-block mb-2"><b>Event Organizer</b></p>`
          }

          if (review.company != null && review.company != '') {
            reviewHTML += `
                  <p class="small">${review.company}</p>`
          }

          reviewHTML += `
                </div>
              </div>

              <div class="col">
                <div class="star position-relative">`

          const fullStars = Math.floor(parseFloat(review.rating))
          const hasHalfStar = review.rating.includes('.5')

          for (let i = 0; i < fullStars; i++) {
            reviewHTML += '<i class="fa-solid fa-star my-2 star"></i>'
          }

          if (hasHalfStar) {
            reviewHTML += '<i class="fa-solid fa-star-half-stroke star"></i>'
          }

          reviewHTML += `
                  <span class="font-500 color-black">on ${review.when_at}</span>
                </div>
                <div class="des color-dark mt-2">`

          if (review.body == null) {
            reviewHTML += `
                  <p><em>This participant left a rating only.</em></p>`
          } else {
            reviewHTML += `
                  <p>${review.body}</p>`
          }

          if (review.reply_text != null && review.reply_text != '') {
            reviewHTML += `
                  <hr class="my-2">
                  <p><b>teambuilding.com reply:</b></p>
                  <p class="mt-2">${review.reply_text}</p>`
          }

          reviewHTML += `
                </div>
              </div>
            </div>`

          $('#preview_reviews').append(reviewHTML)
        })
      },
      error: function (error) {
        console.error('Error fetching reviews:', error)
        window.displayToast('alert', 'Error fetching reviews.')
      },
      complete: function () {
        if (sortOption != 'preview') {
          $('#view_more_reviews').hide()
        }
      }
    })
  }

  const reviewsSort = $('#reviews-sorting')

  if (reviewsSort.length > 0) {
    reviewsSort.on('change', function () {
      const selectedOption = $(this).val()
      fetchReviews(selectedOption)
    })

    fetchReviews('preview')
  }

  window.fetchReviews = fetchReviews
})
